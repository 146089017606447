import React from 'react';
import PropTypes from 'prop-types';

const Progressbar = (props) => {
  const width = props.value ? { width: `${props.value}%` } : null;

  const className = props.isDanger
    ? 'progress-bar bg-danger progress-bar-animated'
    : 'progress-bar progress-bar-striped progress-bar-animated';
  return (
    <div className="progress">
      <div className={className} style={width}>
        {props.text}
      </div>
    </div>
  );
};

Progressbar.propTypes = {
  value: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default Progressbar;
