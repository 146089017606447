import React, { useState, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Select from 'react-select';
import get from 'lodash.get';


import { getProviders } from '../../services/providers';
import { frontendUrl } from '../../services/url';
import reducer from './state/reducer';
import types from './state/types';
import './styles.css';

const findLabelAndValue = (list, value) => {
    return list.find(labelAndValue => labelAndValue.value === value);
};

const AppForm = ({ onCreate, onEdit, handleDelete, app, apps }) => {
    const history = useHistory();
    const [spec, setSpec] = useState([]);
    const [state, dispatch] = useReducer(reducer, {
        canRegister: false,
    });

    const handleFieldChange = name => {
        return e => {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            dispatch({
                type: types.UPDATE_FIELD,
                payload: {
                    name,
                    value,
                },
            });
        }
    };

    const handleSelectChange = name => {
        return value => {
            dispatch({
                type: types.UPDATE_FIELD,
                payload: {
                    name,
                    value,
                },
            });
        }
    }

    const value = (...path) => get(state, ['fields', ...path, 'value']) || '';

    const disabled = (...path) => get(state, ['fields', ...path, 'disabled']) || false;

    const checkValue = (val) => val ? /^[\w]+$/.test(val) : true;

    const getInputValidationClass = (valid) => valid ? 'form-control' : 'form-control input-error';

    const handleToListClick = () => {
        history.push(frontendUrl('/'));
    }

    const gatherProvidersFromState = () => {
        const regProviders = {};
        for (const entry of spec) {
            if (value(entry.name)) {
                regProviders[entry.name] = {};
                //eslint-disable-next-line no-unused-expressions
                entry?.subFields?.forEach(subEntry => {
                    if (subEntry.type === 'select') {
                        regProviders[entry.name][subEntry.name] = value(entry.name, subEntry.name)?.value;
                    } else if (subEntry.type === 'text') {
                        regProviders[entry.name][subEntry.name] = value(entry.name, subEntry.name);
                    } else {
                        throw new Error('Неизвестный тип поля!');
                    }
                });
            }
        }

        return regProviders;
    };

    const handleClick = async e => {
        e.preventDefault();
        const regProviders = gatherProvidersFromState();
        if (!!app) {
            onEdit(regProviders);
        } else {
            if (apps.some(({ bundleId, name }) => bundleId === value('bundleId') || name === value('name'))) {
                alert('Вы пытаетесь зарегистрировать существующее приложение');
            }
            else {
                const app = {
                    name: value('name'),
                    shortName: value('shortName'),
                    bundleId: value('bundleId'),
                };
                app.regProviders = regProviders;
                onCreate(app);
            }
        }
    };

    useEffect(() => {
        const hydrateFields = (spec) => {
            if (!!app) {
                dispatch({
                    type: types.SET_SAVED_ON_SERVER,
                    payload: {
                        app,
                    },
                });
                dispatch({
                    type: types.UPDATE_FIELD,
                    payload: {
                        name: 'name',
                        value: app.name,
                        disabled: true,
                    },
                });
                dispatch({
                    type: types.UPDATE_FIELD,
                    payload: {
                        name: 'shortName',
                        value: app.shortName,
                        disabled: true,
                    },
                });
                dispatch({
                    type: types.UPDATE_FIELD,
                    payload: {
                        name: 'bundleId',
                        value: app.bundleId,
                        disabled: true,
                    },
                });
                for (const entry of spec) {
                    const provider = app.regProviders[entry.name];
                    dispatch({
                        type: types.UPDATE_FIELD,
                        payload: {
                            name: entry.name,
                            value: !!provider,
                            ...(entry.dependencies ? { dependencies: entry.dependencies } : {}),
                            ...(!!provider ? { disabled: !!provider } : {}),
                        },
                    });
                    //eslint-disable-next-line no-unused-expressions
                    entry?.subFields?.forEach(subEntry => {
                        if (subEntry.type === 'select') {
                            const value = findLabelAndValue(subEntry.values, get(app.regProviders, [entry.name, subEntry.name]));
                            dispatch({
                                type: types.UPDATE_FIELD,
                                payload: {
                                    name: `${entry.name}.${subEntry.name}`,
                                    value: value || subEntry.values[0],
                                    disabled: !!provider,
                                },
                            });
                        } else if (subEntry.type === 'text') {
                            const value = get(app.regProviders, [entry.name, subEntry.name]);
                            dispatch({
                                type: types.UPDATE_FIELD,
                                payload: {
                                    name: `${entry.name}.${subEntry.name}`,
                                    value: value,
                                    disabled: !!provider,
                                },
                            });
                        } else {
                            throw new Error('Неизвестный тип поля!');
                        }
                    });
                }
            } else {
                for (const entry of spec) {
                    dispatch({
                        type: types.UPDATE_FIELD,
                        payload: {
                            name: entry.name,
                            value: false,
                            ...(entry.dependencies ? { dependencies: entry.dependencies } : {}),
                        },
                    });
                    //eslint-disable-next-line no-unused-expressions
                    entry?.subFields?.forEach(subEntry => {
                        if (subEntry.type === 'select') {
                            dispatch({
                                type: types.UPDATE_FIELD,
                                payload: {
                                    name: `${entry.name}.${subEntry.name}`,
                                    value: subEntry.values[0],
                                },
                            });
                        } else if (subEntry.type === 'text') {
                            // do nothing
                        } else {
                            throw new Error('Неизвестный тип поля!');
                        }
                    });
                }
            }
        };

        getProviders().then(spec => {
            setSpec(spec);
            hydrateFields(spec);
        })
    }, [app]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <input type="button"
                            onClick={handleToListClick}
                            className="btn btn-outline-secondary" value="К списку приложений" />
                    </nav>
                </div>
            </div>
            <div className="create-app-form">
                <form className="mb-3">
                    <div className="form-group">
                        <label>Имя приложения</label>
                        <input
                            value={value('name')}
                            onChange={handleFieldChange('name')}
                            className="form-control"
                            disabled={disabled('name')}
                        />
                    </div>
                    <div className="form-group">
                        <label>Краткое имя приложения</label>
                        <input
                            value={value('shortName')}
                            onChange={handleFieldChange('shortName')}
                            className={getInputValidationClass(checkValue(value('shortName')))}
                            disabled={disabled('shortName')}
                        />
                        {!checkValue(value('shortName')) && <div className="invalid-value-block">Краткое имя приложения можно писать не используя спец. сиволы</div>}
                    </div>
                    <div className="form-group">
                        <label>Bundle id</label>
                        <input
                            value={value('bundleId')}
                            onChange={handleFieldChange('bundleId')}
                            className="form-control"
                            disabled={disabled('bundleId')}
                        />
                    </div>
                    <div>
                        {spec.map(entry => (
                            <React.Fragment key={`${entry.order}-${entry.name}`}>
                                <div className="form-check">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={value(entry.name)}
                                            onChange={handleFieldChange(entry.name)}
                                            disabled={disabled(entry.name)}
                                            className="form-check-input"
                                        />
                                        {entry.name}
                                        {value(entry.name) && onEdit && (
                                            <button
                                                type="button"
                                                className="close ml-10"
                                                onClick={() => handleDelete(value('bundleId'), entry.name)}
                                            >
                                                <span>&times;</span>
                                            </button>
                                        )}
                                    </label>
                                </div>
                                {value(entry.name) && entry.subFields && entry.subFields.map(subEntry => {
                                    switch (subEntry.type) {
                                        case 'select':
                                            return (
                                                <React.Fragment key={`${subEntry.type}-${subEntry.name}`}>
                                                    <div className="form-group">
                                                        <Select
                                                            options={subEntry.values}
                                                            onChange={handleSelectChange(`${entry.name}.${subEntry.name}`)}
                                                            isDisabled={disabled(entry.name, subEntry.name)}
                                                            value={value(entry.name, subEntry.name)}
                                                        >
                                                        </Select>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        case 'text':
                                            return (
                                                <div className="form-group">
                                                    <label>
                                                        {subEntry.label}
                                                    </label>
                                                    <input
                                                        value={value(entry.name, subEntry.name)}
                                                        onChange={handleFieldChange(`${entry.name}.${subEntry.name}`)}
                                                        className="form-control"
                                                        disabled={disabled(entry.name, subEntry.name)}
                                                    />
                                                </div>
                                            );
                                        default: throw new Error('Неизвестный тип поля!');
                                    }
                                })}
                            </React.Fragment>
                        ))}
                    </div>
                    <div>
                        <button
                            onClick={handleClick}
                            className="btn btn-secondary"
                            disabled={(!!app && !state.isChanged) || (!app && !state.canRegister && checkValue(value('shortName')))}
                        >
                            {!!app ? 'Сохранить изменения' : 'Зарегистрировать'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

AppForm.propTypes = {
    onCreate: PropTypes.func,
    onEdit: PropTypes.func,
    app: PropTypes.object,
    apps: PropTypes.array
};

export default AppForm;
