import {authorizationHeader} from './auth';
import {backendUrl} from './url';

const getProviders = async () => {
  const response = await fetch(
    backendUrl('/regProviders'),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authorizationHeader(),
      },
    },
  );
  const body = await response.json();
  const providers = body;
  return providers;
};

export {
  getProviders,
}
