import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import {useHistory} from 'react-router-dom';
import {
  getApps,
  deleteApp,
  patchZeroAttemps,
  restartBigQueryTable,
} from '../../services/apps';
import AppsTable from '../../components/AppsTable';
import Header from '../../components/Header';
import {frontendUrl} from '../../services/url';
import ErrorContext from '../../ErrorContext';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Main = () => {
    const {setError} = useContext(ErrorContext);
    const history = useHistory();
    const [apps, setApps] = useState([]);
    const timer = useRef(null);

    const handleRequest = async () => {
        const responseApps = await getApps()
        setApps(responseApps)
    }

    const handleRequestForPatchZeroAttemps = async (id) => {
        patchZeroAttemps(id);
        handleRequest()
    }

    const handleDelete = async id => {
        confirmAlert({
            message: 'Уверены, что хотите удалить?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await deleteApp(id);
                        const apps = await getApps() || [];
                        setApps(apps);
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    };

    useEffect(() => {
        (async () => {
            try {
                handleRequest();
                timer.current = setInterval(() => {
                    handleRequest()
                }, 1500);
            } catch (e) {
                setError(e.message);
                history.push(frontendUrl('/login'));
            }
        })();

        return () => {
            clearInterval(timer.current);
        }
    }, [setError, history]);

    return (
        <>
            <Header />
            <AppsTable apps={apps} restartBigQueryTable={restartBigQueryTable} onZeroAttemps={handleRequestForPatchZeroAttemps} onDelete={handleDelete} />
        </>
    );
}

export default Main;
