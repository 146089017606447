import React from 'react';
import PropTypes from 'prop-types';
import { Popover, OverlayTrigger } from 'react-bootstrap';

const Status = ({ app }) => {
  let renderStatus = [];
  for (const nameSevice in app.regProviders) {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          {JSON.parse(app.lastRegAttemptError).message}
        </Popover.Content>
      </Popover>
    );
    !app.regProviders[`${nameSevice}`].isRegistered &&
      renderStatus.push(
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
          <span className="badge badge-danger">{nameSevice}</span>
        </OverlayTrigger>
      );
  }
  return (
    <>
      {renderStatus.map((item, index) => (
        <span key={index}>{item} </span>
      ))}
    </>
  );
};

Status.propTypes = {
  app: PropTypes.object.isRequired,
};

export default Status;
