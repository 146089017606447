import {backendUrl} from './url';

const ID_TOKEN = 'id_token';
const EMAIL = 'email';

const authUrl = path => {
  return backendUrl(`/auth${path}`);
};

const isAuthenticated = () => {
  return localStorage.getItem(ID_TOKEN) !== null;
};

const issueToken = async code => {
  const response = await fetch(
    authUrl('/google/token'),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code
      })
    },
  );
  const body = await response.json();
  if (body.id_token) {
    localStorage.setItem(ID_TOKEN, body.id_token);
    return true;
  } else {
    return false;
  }
}

const authorizationHeader = () => {
  const id_token = localStorage.getItem(ID_TOKEN);
  if (id_token) {
    return {
      Authorization: `Bearer ${id_token}`,
    }
  } else {
    return {};
  }
};

const logout = () => {
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(EMAIL);
}

const getEmailFromToken = async () => {
  let email = localStorage.getItem(EMAIL);
  if (!email) {
    const response = await fetch(
      authUrl('/google/token'),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authorizationHeader(),
        }
      },
    );
    const body = await response.json();
    email = body.email;
    if (email) {
      localStorage.setItem(EMAIL, email);
    }
  }
  return email;
};

export {
  isAuthenticated,
  issueToken,
  authorizationHeader,
  logout,
  getEmailFromToken,
}
