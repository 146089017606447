import types from './types';
import get from 'lodash.get';
import set from 'lodash.set';

const isChanged = (savedOnServer, fieldsState) => {
  const isChanged = Object.entries(fieldsState)
    .some(([fieldName, field]) => {
      if (field.disabled) {
        return false;
      }
      const isSelectedOnServer = !!savedOnServer.regProviders[fieldName];
      const isChanged = field.value !== isSelectedOnServer;
      return isChanged;
    });
  return isChanged;
}

const reducer = (state, action) => {
  switch (action.type) {
  case types.UPDATE_FIELD: {
    const fieldsState = state.fields;
    const {name, ...propsToUpdate} = action.payload;
    const validatedPropsToUpdate = Object.fromEntries(Object.entries(propsToUpdate).map(([propName, propValue]) => {
      if (propName === 'value' && name === 'shortName') {
        propValue = propValue?.replace(/\s/g, '');
      }
      return [propName, propValue];
    }));
    const field = get(fieldsState, name);
    const newField = {...field, ...validatedPropsToUpdate};
    const newFieldsState = {...fieldsState};
    set(newFieldsState, name, newField);

    //eslint-disable-next-line no-unused-expressions
    newField?.dependencies?.forEach(
      (depName) => {
        const dep = get(newFieldsState, depName);
        const newDep = {
          ...dep,
          disabled: !newField.value,
        }
        set(newFieldsState, depName, newDep);
        return newFieldsState;
      }
    );
    const canRegister = 
      newFieldsState?.name?.value?.length > 0 &&
      newFieldsState?.shortName?.value?.length > 0 &&
      (newFieldsState?.GameAnalytics?.value ? newFieldsState?.GameAnalytics?.title?.value?.length > 0 : true) &&
      newFieldsState?.bundleId?.value?.length > 0;
    return {
      ...state,
      fields: newFieldsState,
      canRegister,
      ...(state.savedOnServer ? {isChanged: isChanged(state.savedOnServer, newFieldsState)} : {}),
    };
  }
  case types.SET_SAVED_ON_SERVER: {
    let { app } = action.payload;
    return {
      ...state,
      savedOnServer: app,
    };
  }
  default:
    throw new Error(`No such action ${action.type}!`);
  }
}

export default reducer;
