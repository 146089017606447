import React, {useContext, useEffect, useState} from 'react';
import ErrorContext from "../../ErrorContext";

const StatusPanel = () => {
    const {error, success, setSuccess, setError} = useContext(ErrorContext);
    const [isShown, setIsShown] = useState();
    useEffect(() => {
        if (error || success) {
            setIsShown(true);
            const timeout = setTimeout(() => {
                setIsShown(false);
                setSuccess('');
                setError('');
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [error, success, setError, setSuccess]);
    return (<>
        {isShown && <div className="container">
            <div className="row">
                <div className="col-12">
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}
                    {success && (
                        <div className="alert alert-success" role="alert">
                            {success}
                        </div>
                    )}
                </div>
            </div>
        </div>}
    </>);

};

export default StatusPanel;
