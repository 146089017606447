import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { authorizationHeader } from '../../services/auth';
import { backendUrl } from '../../services/url';
import BigQueryTablePanel from '../BigQueryTablePanel';
import Progressbar from '../Progressbar';
import FailPanel from '../FailPanel';

const RegProviderStatus = ({ app, onZeroAttemps, restartBigQueryTable }) => {
  const [maxRegAttemps, setMaxRegAttemps] = useState(0);
  const { registered, total } = app.regProvidersCount;
  const registeredPercent = Math.round((registered / total) * 100);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(backendUrl('/regAttempsLimit'), {
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        }
    });
      const body = await response.json();
      typeof body === 'number' && setMaxRegAttemps(body);
    };
    fetchData();
  }, []);

  const handleZeroAttempsClick = async () => {
    await onZeroAttemps(app.bundleId);
  };

  return app.isRegistered ? (
    <Row>
      <Col>Зарегистрировано</Col>
      {app.regProviders.hasOwnProperty('Adjust Export') && (
        <Col>
          <BigQueryTablePanel
            appName={app.shortName}
            bundleId={app.bundleId.replace(/\./g,"-").toLowerCase()}
            restartBigQueryTable={restartBigQueryTable}
          />
        </Col>
      )}
    </Row>
  ) : maxRegAttemps !== 0 ? (
    app.regAttempts === maxRegAttemps ? (
      <FailPanel
        app={app}
        registeredPercent={registeredPercent}
        textProgressBar={`${registered}/${total}`}
        handleZeroAttempsClick={handleZeroAttempsClick}
      />
    ) : (
      <Progressbar value={registeredPercent} text={`${registered}/${total}`} />
    )
  ) : (
    'Loading...'
  );
};

RegProviderStatus.propTypes = {
  app: PropTypes.object.isRequired,
  onZeroAttemps: PropTypes.func,
};

export default RegProviderStatus;
