import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { authorizationHeader } from '../../services/auth';
import { backendUrl } from '../../services/url';
import RestartBigQueryTableRegisterPanel from '../RestartBigQueryTableRegisterPanel';

const BigQueryTablePanel = ({ appName, bundleId, restartBigQueryTable }) => {
  const [bucketObject, setBucketObject] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        backendUrl(`/getBucketData/${bundleId}`),
        {
          headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
          },
        }
      );
      const body = await response.json();
      setBucketObject(body);
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (bucketObject == null) {
    return null;
  }
  return (
    <>
      {bucketObject && bucketObject.items && bucketObject.items.length > 0 ? (
        <RestartBigQueryTableRegisterPanel
          appName={appName}
          bucketObject={bucketObject}
          bundleId={bundleId}
          restartBigQueryTable={restartBigQueryTable}
        />
      ) : (
        <Badge variant='warning'>Нужно настроить экспорт в Adjust</Badge>
      )}
    </>
  );
};

BigQueryTablePanel.propTypes = {
  bundleId: PropTypes.string.isRequired,
  restartBigQueryTable: PropTypes.func,
};

export default BigQueryTablePanel;
