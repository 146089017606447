import React, {useContext} from 'react';
import {backendUrl} from '../../services/url';
import ErrorContext from '../../ErrorContext';
import './styles.css';

const GoogleAuthLoginForm = () => {
  const {error} = useContext(ErrorContext);
  return (
    <div className="login-container">
      {error && (
        <div className="alert alert-danger">
          {error}
        </div>
      )}
      <h2>Добро пожаловать</h2>

      <div className="login-container-text">Войдите с помощью учетной записи google</div>

      <div className="google-btn" onClick={() => window.location.replace(backendUrl('/auth/google/code'))}>
        <div className="google-icon-wrapper">
          <img
            className="google-icon"
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            alt="google-icon"
          />
        </div>
        <p className="btn-text"><b>Sign in with google</b></p>
      </div>
    </div>
  );
};

export default GoogleAuthLoginForm;
