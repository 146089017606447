import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import { authorizationHeader } from '../../services/auth';
import { backendUrl } from '../../services/url';

const RestartBigQueryTableRegisterPanel = ({
  bucketObject,
  bundleId,
  restartBigQueryTable,
  appName,
}) => {
  const [needTable, setNeedTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        backendUrl(`/getBQTables/adjust_${bundleId.replace(/-/g,"_")}`),
        {
          headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
          },
        }
      );
      const body = await response.json();
      (body.name === "Error" || body.totalItems === 0) && setNeedTable(true);
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const restartBigQueryTableClick = async (bucketObject) => {
    setIsLoading(true);
    await restartBigQueryTable(bucketObject, appName);
    setIsLoading(false);
    setNeedTable(false);
  };

  return (
    <>
      {needTable && (
        <>
          <Button
            onClick={() => restartBigQueryTableClick(bucketObject.items)}
            size="sm"
            variant='success'
          >
            {isLoading && (
              <Spinner size='sm' animation='border' variant='warning' />
            )}
            Создать таблицу
          </Button>
        </>
      )}
    </>
  );
};

RestartBigQueryTableRegisterPanel.propTypes = {
    bundleId: PropTypes.string.isRequired,
    onZeroAttemps: PropTypes.func,
};

export default RestartBigQueryTableRegisterPanel;
