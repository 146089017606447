import { frontendUrl, backendUrl } from './url';
import { authorizationHeader } from './auth';

const checkError = (body) => {
    if (body && body.error) {
        if (body.error.message === 'Вы не авторизованы!') window.location = frontendUrl('/login');
        const message = body.error.message || 'Неизвестная ошибка!';
        throw new Error(message);
    }
}

const postApp = async (app) => {
    const response = await fetch(backendUrl('/apps'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
        body: JSON.stringify(app),
    });

    if (response.status === 204) return void 0;
    const body = await response.json();
    checkError(body);
};

const deleteApp = async (bundleId) => {
    const response = await fetch(backendUrl(`/apps/${bundleId}`), {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
    });

    if (response.status === 204) return void 0;
    const body = await response.json();
    checkError(body);
};

const postRegistrations = async (bundleId, regProviders) => {
    const response = await fetch(backendUrl('/registrations'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
        body: JSON.stringify({
            bundleId,
            regProviders,
        }),
    });

    if (response.status === 204) return void 0;
    const body = await response.json();
    checkError(body);
};

const addRegProvidersCountField = (apps) => {
    for (const app of apps) {
        let total = 0;
        let registered = 0;
        for (const providerValue of Object.values(app.regProviders)) {
            total++;
            if (providerValue.isRegistered) {
                registered++;
            }
        }
        app.regProvidersCount = {
            total,
            registered,
        };
    }
};

const getApp = async (bundleId) => {
    const response = await fetch(backendUrl(`/apps/${bundleId}`), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
    });

    const body = await response.json();
    checkError(body);
    return body;
};

const getApps = async () => {
    const response = await fetch(backendUrl('/apps'), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
    });
    const apps = await response.json();
    checkError(apps);
    addRegProvidersCountField(apps);
    return apps;
};

const patchZeroAttemps = async (bundleId) => {
    const response = await fetch(backendUrl('/zeroAttepmsByBundleId'), {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
        body: JSON.stringify({
            bundleId,
        }),
    });

    if (response.status === 200) return 'ok';
    const body = await response;
    checkError(body);
};

const restartBigQueryTable = async (bucketObject,appName) => {
    const pathList = bucketObject && bucketObject.map(({bucket, name})=>`gs://${bucket}/${name}`)
    let replacedBucketName = bucketObject[0].bucket.split('-');
    replacedBucketName.pop();
    const dataset = `adjust_${replacedBucketName.join("_")}`
    const bucketName = bucketObject[0].bucket
    const response = await fetch(backendUrl('/createBQTableAndTransferData'), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
        body: JSON.stringify({
            path:pathList,
            dataset,
            bucketName,
            appName:appName
        }),
    });

    const body = await response.json();
    checkError(body);
    return body;
};

const deleteProvider = async (bundleId, providerName) => {
    const response = await fetch(backendUrl(`/regProvider/${bundleId}`), {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            ...authorizationHeader(),
        },
        body: JSON.stringify({
            providerName,
        }),
    });

    if (response.status === 204) return void 0;
    const body = await response.json();
    checkError(body);
};

export {
    postApp,
    deleteApp,
    postRegistrations,
    getApps,
    getApp,
    patchZeroAttemps,
    restartBigQueryTable,
    deleteProvider,
};
