import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {logout, getEmailFromToken} from '../../services/auth';
import {frontendUrl} from '../../services/url';
import StatusPanel from "../StatusPanel";

const Header = () => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const handleClick = () => {
    logout();
    history.push(frontendUrl('/login'));
  };
  useEffect(
    () => {
      (async () => {
        setEmail(await getEmailFromToken());
      })();
    },
    [],
  );
  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark fixed-column bg-dark">
        <div className="collapse navbar-collapse">
          <div className="mr-auto"></div>
          <div className="form-inline my-2 my-lg-0">
            <span className="text-white mr-2">{email}</span>
            <button
              className="btn btn-outline-danger"
              onClick={handleClick}
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
      <StatusPanel />
    </header>
  );
};

export default Header;
