import React, {useContext, useEffect, useState} from 'react';
import {getApp, postRegistrations, deleteProvider} from "../../services/apps";
import {frontendUrl} from "../../services/url";
import AppForm from "../../components/AppForm";
import Header from "../../components/Header";
import ErrorContext from "../../ErrorContext";
import {useHistory, useParams} from "react-router-dom";

const Create = () => {
    const {setError, setSuccess} = useContext(ErrorContext);
    const {id} = useParams();
    const history = useHistory();
    const handleEdit = async regProviders => {
        try {
            await postRegistrations(id, regProviders);
            setSuccess('Изменения сохранены');
        } catch(e) {
            setError(e.message);
        }
        history.push(frontendUrl('/'))
    }
    const handleDelete = async (bundleId, providerName) => {
        try {
            await deleteProvider(bundleId, providerName);
            setSuccess('Изменения сохранены');
            window.location.reload();
        } catch(e) {
            setError(e.message);
        }
        history.push(frontendUrl(`/edit/${id}`))
    }
    const [app, setApp] = useState();
    useEffect(
        () => {
            (async () => {
                try {
                    setApp(await getApp(id));
                } catch(e) {
                    setError(e.message);
                    history.push(frontendUrl('/login'))
                }
            })();
        },
        [setError, history, id],
    );
    return (
        <>
            <Header />
            <AppForm onEdit={handleEdit} handleDelete={handleDelete} app={app} />
        </>
    );
};

export default Create;
