import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppForm from '../../components/AppForm';
import Header from '../../components/Header';
import ErrorContext from '../../ErrorContext';
import { postApp, getApps } from '../../services/apps';
import { frontendUrl } from '../../services/url';

const Create = () => {
    const { setError, setSuccess } = useContext(ErrorContext);
    const [apps, setApps] = useState([]);
    const history = useHistory();

    const handleCreate = async app => {
        try {
            await postApp(app);
            setSuccess('Приложение добавлено');
        } catch (e) {
            setError(e.message);
        }

        history.push(frontendUrl('/'));
    }

    useEffect(() => {
        (async () => {
            try {
                setApps(await getApps());
            } catch (e) {
                setError(e.message);
                history.push(frontendUrl('/login'))
            }
        })();
    }, [setError, history]);

    return (
        <>
            <Header />
            <AppForm apps={apps} onCreate={handleCreate} />
        </>
    );
};

export default Create;
