import React from 'react';
import PropTypes from 'prop-types';
import RegProviderStatus from '../../components/RegProviderStatus';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { Table, Row, Col } from 'antd';
import { frontendUrl } from '../../services/url';

const AppsTable = (props) => {
    const history = useHistory();
    const columns = [
        {
            title: 'Имя приложения',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            }
        },
        {
            title: 'Bundle id',
            dataIndex: 'bundleId',
            key: 'bundleId',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (text, app) => (
                <RegProviderStatus
                    app={app}
                    onZeroAttemps={props.onZeroAttemps}
                    restartBigQueryTable={props.restartBigQueryTable}
                />
            ),
        },
        {
            title: 'Действия',
            dataIndex: 'action',
            key: 'action',
            render: (text, app) => (
                <div className="button-with-x-container">
                    <input
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => handleEditClick(app.bundleId)}
                        value="Редактировать"
                    />
                    <button
                        type="button"
                        className="close ml-10"
                        onClick={() => props.onDelete(app.bundleId)}
                    >
                        <span>&times;</span>
                    </button>
                </div>
            ),
        }
    ];

    const handleAddClick = () => {
        history.push(frontendUrl('/create'));
    };

    const handleEditClick = (id) => {
        history.push(frontendUrl(`/edit/${id}`));
    };

    return (
        <>
            <Row>
                <Col offset={6} span={14}>
                    <Table
                        dataSource={props.apps.map(el => ({ ...el, key: el._id }))}
                        columns={columns}
                        pagination={{ pageSize: 10 }}
                    />
                </Col>
            </Row>
            <Row>
                <Col offset={6} span={14}>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <input
                            type="button"
                            className="btn btn-secondary"
                            value="Зарегистрировать новое приложение"
                            onClick={handleAddClick}
                        />
                    </nav>
                </Col>
            </Row>
        </>
    );
};

AppsTable.propTypes = {
    apps: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default AppsTable;
