import React, {useState} from 'react';
import Login from '../src/pages/Login';
import OAuthCallback from './pages/OAuthCallback';
import Main from '../src/pages/Main';
import Create from '../src/pages/Create';
import Edit from '../src/pages/Edit';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import {isAuthenticated} from './services/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import {frontendUrl} from './services/url';
import ErrorContext from './ErrorContext';
import 'antd/dist/antd.css';

const PrivateRoute = ({ children, ...props}) => (
    <Route {...props}>
        {isAuthenticated()
            ? children
            : <Redirect to={frontendUrl('/login')} />
        }
    </Route>
);

const App = () => {
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    return (
        <ErrorContext.Provider value={{ error, setError, success, setSuccess }}>
            <Router>
                <Switch>
                    <Route path={frontendUrl('/login')}>
                        <Login />
                    </Route>
                    <Route path={frontendUrl('/callback')}>
                        <OAuthCallback />
                    </Route>
                    <PrivateRoute path={frontendUrl('/create')}>
                        <Create />
                    </PrivateRoute>
                    <PrivateRoute path={frontendUrl('/edit/:id')}>
                        <Edit />
                    </PrivateRoute>
                    <PrivateRoute path={frontendUrl('/')}>
                        <Main />
                    </PrivateRoute>
                </Switch>
            </Router>
        </ErrorContext.Provider>
    );
}

export default App;
